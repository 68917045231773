import "./App.scss";
import logo from "./assets/images/logo.png";
import logo2 from "./assets/images/logo-2.png";
import ImgIntro from "./assets/images/img-intro.png";
import ImgAboutUs from "./assets/images/img-about-us.png";
import ImgGroup1 from "./assets/images/img-group1.png";
import ImgGroup2 from "./assets/images/img-group2.png";
import ImgGroup3 from "./assets/images/img-group3.png";
import ImgGroup4 from "./assets/images/img-group4.png";
import bgIntro from './assets/images/bg-intro.png';

function App() {
	return (
		<div className="App">
			<header className="page-header">
				<div className="container">
					<ul className="header-pc">
						<li>
							<a href="">UNISWAP</a>
						</li>
						<li>
							<a href="">DEXTOOLS</a>
						</li>
						<li>
							<a href="">
								<img className="logo" src={logo} alt="" />
							</a>
						</li>
						<li>
							<a href="https://t.me/OPOXeth" target="_blank"  rel="noopener noreferrer">TELEGRAM</a>
						</li>
						<li>
							<a href="https://twitter.com/OPOXeth" target="_blank"  rel="noopener noreferrer">TWITTER</a>
						</li>
					</ul>
					<div className="header-mobile">
						<a href="">
							<img className="logo" src={logo} alt="" />
						</a>
						<ul>
							<li>
								<a href="">UNISWAP</a>
							</li>
							<li>
								<a href="">DEXTOOLS</a>
							</li>
							<li>
								<a href="https://t.me/OPOXeth" target="_blank"  rel="noopener noreferrer">TELEGRAM</a>
							</li>
							<li>
								<a href="https://twitter.com/OPOXeth" target="_blank"  rel="noopener noreferrer">TWITTER</a>
							</li>
						</ul>
					</div>
				</div>
			</header>
			<main className="page-main">
				<div className="container">
					<section className="section-intro">
						<div className="left-content">
							<h1 className="title">Only Possible On X</h1>
							<p className="content">
								$OPOX comes to the crypto space with an awesome launch! Our mission is to bring some serious excitement
								and innovation to the world of cryptocurrency through X!!
								<br />
								0xCAe856CFe760BA499dE36E650D19EfDc3B642807
							</p>
						</div>
						<div className="right-content">
							{/* <img className="bg-intro" src={bgIntro} alt=""/> */}
							<img src={ImgIntro} alt="" />
						</div>
					</section>
					<section className="section-about-us">
						<div className="left-content">
							<img src={ImgAboutUs} alt="" />
						</div>
						<div className="right-content">
							<h3 className="title">who we are?</h3>
							<p className="content">
								$OPOX comprises a team of passionate and cool individuals committed to crafting an unforgettable
								experience for crypto enthusiasts. So, gear up to accompany us on this exciting journey as we disrupt
								the crypto sphere!
							</p>
						</div>
					</section>
					<section className="section-communication">
						<div className="item">HUGE COMMUNITY</div>
						<div className="item">NO TAX - NO FEES</div>
						<div className="item">SUPPORT BY WHALES</div>
						<div className="item">BIG MARKETING</div>
					</section>
					<section className="section-join-us">
						<h3 className="title">JOIN US NOW!!</h3>
						<div className="actions">
							<a className="img-box" target="_blank"  rel="noopener noreferrer" href="https://twitter.com/OPOXeth">
								<img src={ImgGroup1} alt="" />
							</a>
							<a className="img-box" target="_blank"  rel="noopener noreferrer" href="https://twitter.com/OPOXeth">
								<img src={ImgGroup2} alt="" />
							</a>
							<a className="img-box" target="_blank"  rel="noopener noreferrer" href="https://twitter.com/OPOXeth">
								<img src={ImgGroup3} alt="" />
							</a>
							<a className="img-box" target="_blank"  rel="noopener noreferrer" href="https://twitter.com/OPOXeth">
								<img src={ImgGroup4} alt="" />
							</a>
						</div>
					</section>
				</div>
			</main>
			<footer className="page-footer">
				<div className="container">
					<div className="logo-footer">
						<img src={logo2} alt="" />
					</div>
					<p>$OPOX | All Rights Reserved | 2023 | developer@opox.xyz</p>
				</div>
			</footer>
		</div>
	);
}

export default App;
